.export-link {
    padding-top: 20px;
    padding-left: 15px;
}

td.Polaris-DataTable__Cell:last-child {
    display: none;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon {
    order: 2;
  }
.Polaris-Header-Title__SubTitle.custom  {
    margin-bottom: 3rem;
}
.Polaris-Header-Title__SubTitle.custom .Polaris-Icon{
    display: inline-block; margin-left: 1rem;
    position: relative;
    top: 4px;
}
.card-login {
    max-width: 600px;
    margin: 0 auto;
}
.Polaris-Page-Header__Navigation {
    margin-bottom: 30px;
}
.actions {
    margin-top: 15px;
}
.time-group {
    margin-top: 24px;
}
.custom-select {
    border: none;
    background: none;
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    -webkit-appearance: none;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: none;
    outline: none;
}

.text-mid {
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
}
.text-mid + .rule-block {
    padding-left: 0;
}
.text-left {
    text-align: left !important;
}
.text-left .Polaris-Icon {
    display: inline-block;
}
.add-rule-btn {
    display: none;
}
.rule-block-wrapper:last-child .add-rule-btn {
    display: block;
    margin-top: 20px;
}
.rule-block {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    padding-left: 20px;
    margin-top: 20px;
}
.remove-rule-btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    margin-right: -10px;
}
.Polaris-InlineError {
    margin-top: 10px;
    font-size: 12px;
}
.layout {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}
.no-textdeco {
    text-decoration: none;
}

.actions-toolbar {
    margin-bottom: 30px;
}

.Polaris-Modal-CloseButton--withoutTitle {
    z-index: 99;
}
.ApplicabilityDate__Row .Polaris-Stack__Item     {
    flex: 1 1 22rem;
    min-width: 22rem;
    max-width: calc(100% - 1.6rem);
}
.InventoryTags {
    padding: 15px 0;
}
.InventoryTags .Polaris-Tag {
    margin-right: 4px;
    margin-bottom: 4px;
}
.stack__condition .Polaris-TextField {
    max-width: 130px;
}
.stack__condition .Polaris-Stack + .Polaris-Stack{
    padding-top: 20px;
}

.bottom-action {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid rgb(223, 227, 232);
}
.conected-row {
    position: relative;
    display: flex;
    margin: 0 -10px;
}
.conected-row__wrapper {
    padding: 0 10px;
}
.conected-row  > div {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 10px;
}
.custom > a {
    text-decoration: none;
    color: inherit;
}

.full-layout .Polaris-Layout__Annotation {display: none !important;}
.checkbox-wrapper-in-stack {
    margin-top: 25px;
}